import React from "react";
import { PageHeader, Button, Modal, Card, Row, Col, Alert, notification, Select, Spin, Empty } from 'antd';
import { InfoCircleOutlined, FullscreenOutlined, FolderOutlined } from '@ant-design/icons';
import { withTranslation} from 'react-i18next'
import debounce from 'lodash/debounce';
import { callFullReindex, callFolderReindex } from "../../apicalls/callReindex";
import { fetchEntries } from "../../apicalls/fetchEntries";
import { isUndefined } from "../../utils/JsObjectHelper";

const { Option } = Select;

const openNotificationWithIcon = (type, translator) => {
    notification[type]({
        message: (type==="success" ? translator('setup.reindex.notificationReindexSuccessfulTitle') : translator('setup.reindex.notificationReindexErrorTitle')),
        description: (type==="success" ? translator('setup.reindex.notificationReindexSuccessfulText') : translator('setup.reindex.notificationReindexErrorText')),
        placement: 'bottomRight'
        });
};

class Reindex extends React.Component {

    constructor(props) {
        super(props);

        this.onFolderSearch = this.onFolderSearch.bind(this);
        this.onFolderSearch = debounce(this.onFolderSearch, 800);

        this.state = {
            fullReindexRunning: false,
            folderReindexRunning: false,
            reindexFolderId: null,
            fetching: false,
            fetchedFolders: [],
        };
    }

    onFolderSearch = (value) => {
        this.setState({ fetching: true });
        fetchEntries(value, "reindexFolder", data => this.onFolderFetched(data), null, ["folder"]);
    }

    onFolderFetched = (data) => {
        this.setState({ fetching: false, fetchedFolders: data });
    }

    onFolderSelected = (selectedFolderId) => {
        this.setState({ reindexFolderId: selectedFolderId });
    }

    clearSelectedFolder = () => {
        this.setState({ reindexFolderId: null, fetchedFolders: [] });
    }

    onRunFolderReindex = () => {
        this.setState({ folderReindexRunning : true });
        callFolderReindex(this.onFolderReindexFinished, this.state.reindexFolderId);
    }

    onFolderReindexFinished = (isSuccess) => {
        const {t} = this.props;
        this.setState({ folderReindexRunning : false });
        openNotificationWithIcon((isSuccess ? "success" : "error"), t);
        this.clearSelectedFolder();
    }

    onRunFullReindex = () => {
        this.setState({ fullReindexRunning : true });
        callFullReindex(this.onFullReindexFinished);
    }

    onFullReindexFinished = (isSuccess) => {
        const {t} = this.props;
        this.setState({ fullReindexRunning : false });
        openNotificationWithIcon((isSuccess ? "success" : "error"), t);
    }

    render () {
        const {t} = this.props;

        let info = () => {
            Modal.info({
                title: t('setup.reindex.infoBoxTitle'),
                content: (
                    <div>
                         {t('setup.reindex.infoBoxContent')} 
                    </div>
                ),
                onOk() {
                },
            });
        };

        let fullReindexInfoBox = (this.state.fullReindexRunning ? 
            <Alert message={t('setup.reindex.fullReindexRunningInfoBox')} type="warning" showIcon />
            :
            <Alert message={t('setup.reindex.fullReindexInfoBox')} type="info" showIcon />
            );
        let folderReindexInfoBox = (this.state.folderReindexRunning ? 
            <Alert message={t('setup.reindex.folderReindexRunningInfoBox')} type="warning" showIcon />
            :
            null
            // <Alert message={t('setup.reindex.folderReindexInfoBox')} type="info" showIcon />
            );

        let folderOptions = [];

        if (!isUndefined(this.state.fetchedFolders)) {
            folderOptions = this.state.fetchedFolders.map(f => <Option value={f.id} key={f.id} disabled={f.isDisabled}>{f.name}</Option>)
        }

        return <div>
            <PageHeader
                title={t('setup.reindex.headerDetail')}
                subTitle={t('setup.reindex.headerDetailLabel')}
                extra={[
                    <Button key="info-circle-button-key" icon={<InfoCircleOutlined />} /* type="primary" */ onClick={info}>
                    </Button>,
                ]}
            >
            </PageHeader>
            <Row gutter={[20, 20]}>
                <Col xs={24} sm={24} md={24} lg={24} xl={12}>
                    <Card 
                        title={t('setup.reindex.fullReindexTitle')}
                        actions={[
                            <Button icon={<FullscreenOutlined />} loading={this.state.fullReindexRunning} onClick={this.onRunFullReindex}>{t('setup.reindex.btnFullReindex')}</Button>
                        ]}
                    >
                        {fullReindexInfoBox}
                    </Card>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={12}>
                    <Card 
                        title={t('setup.reindex.folderReindexTitle')}
                        actions={[
                            <Button 
                                icon={<FolderOutlined />} 
                                loading={this.state.folderReindexRunning} 
                                disabled={this.state.reindexFolderId === null || this.state.fetching}
                                onClick={this.onRunFolderReindex}
                                >{t('setup.reindex.btnFolderReindex')}</Button>
                        ]}
                    >
                        {folderReindexInfoBox}
                        <Select
                            placeholder={t('setup.reindex.folderReindexSelectHint')}
                            style={{ width: 450 }}
                            onSelect={this.onFolderSelected}
                            value={this.state.reindexFolderId}
                            onSearch={this.onFolderSearch}
                            filterOption={false}
                            optionFilterProp="children"
                            showSearch
                            allowClear
                            onClear={this.clearSelectedFolder}
                            notFoundContent={this.state.fetching ? <Spin size="small" /> : <Empty></Empty>}
                        >
                            {folderOptions}
                        </Select>
                    </Card>
                </Col>
            </Row>
            
        </div>;
    }
}

export default withTranslation() (Reindex);

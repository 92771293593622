import React from "react";
import PropTypes from "prop-types";
import { bmcEntryShape, folderPropShape } from "../../../shapes/BmcEntryShape";
import { Progress, Table } from "antd";
import { Link } from "react-router-dom";
import { withTranslation } from 'react-i18next'
import { highlightSearchedText } from "../../../utils/TextHighlighter";
import { isEmptyObject, isEmptyValue, isUndefined } from "../../../utils/JsObjectHelper";
import { getColumnCustomAttributeProps, getColumnCustomAttributePropsWithFilter, getColumnSearchProps, getColumnNameValueTag } from "../../../utils/TableHelper";
import { entryTypesRequestResult } from "../../../shapes/RequestResult";

const deepEqual = require('deep-equal');

class EntryChildTable extends React.Component {

    state = {
        searchText: '',
        searchedColumn: '',
        childTablePageSize: (isEmptyObject(localStorage.getItem("childTablePageSize")) ? 30 : parseInt(localStorage.getItem("childTablePageSize"))),
        filteredTypes: []
    };

    //add columns created, createdByUserName, updated, modifiedByUserName to table if they are selected in folder setup
    addTechnicalAttributes = (columnList, t) => {
        if (!isEmptyValue(this.props.folderProperties) &&
            !isEmptyValue(this.props.folderProperties.childrenDisplayTechAttr)) {
            if (this.props.folderProperties.childrenDisplayTechAttr.includes("created")) {
                columnList.push({ ...getColumnCustomAttributeProps("created", t('app.entry.tools.technical.tblCreated'), "date", t) });
            }
            if (this.props.folderProperties.childrenDisplayTechAttr.includes("createdByUserName")) {
                columnList.push({ ...getColumnCustomAttributeProps("createdByUserName", t('app.entry.tools.technical.tblCreatedBy'), "identity", t, { identityDataIndex: "createdByUserId" }) });
            }
            if (this.props.folderProperties.childrenDisplayTechAttr.includes("updated")) {
                columnList.push({ ...getColumnCustomAttributeProps("updated", t('app.entry.tools.technical.tblLastUpdated'), "date", t) });
            }
            if (this.props.folderProperties.childrenDisplayTechAttr.includes("modifiedByUserName")) {
                columnList.push({ ...getColumnCustomAttributeProps("modifiedByUserName", t('app.entry.tools.technical.tblLastUpdatedBy'), "identity", t, { identityDataIndex: "modifiedByUserId" }) });
            }
        }
    };

    addFillQualityColumn = (columnList, t) => {
        if (!isEmptyValue(this.props.folderProperties) &&
            !isEmptyValue(this.props.folderProperties.childrenDisplayTechAttr)) {
            if (this.props.folderProperties.childrenDisplayTechAttr.includes("fillQuality")) {
                let fillQualityColumnDefinition = {
                    title: t('app.entry.view.tblEntryFillQuality'),
                    //dataIndex: null,
                    key: 'fillQuality',
                    width: 100,
                    render: (text, record) => {
                        //let stats = ["success", "exception", "normal", "active"];
                        let thisStatus = "active";
                        let thisTitle = "";
                        let thisPercent = 100;

                        if (this.props.entryTypesRequestResult.getState().isDone()) {
                            //thisPercent = Math.floor(Math.random()*100)
                            let eType = this.props.entryTypesRequestResult.getData().find(eT => eT.type === record.type);

                            //PERCENTAGE calculation
                            //let filledCount = 0;
                            let filledCount = 1;
                            let percentageAttributes = null;
                            //Description uz se nenacita v seznamu ve slozce
                            /*if (!isEmptyValue(record.description) && !isEmptyValue(record.description.trim())) {
                                filledCount++;
                            } else {
                                thisTitle = t('app.entry.view.lblEntryFillQualityMissingDescription');
                            }*/
                            if (!isUndefined(eType)) {
                                percentageAttributes = eType.properties.attributes.filter(att => ["string", "number", "boolean", "date"].includes(att.type)).map(a => a.techName);
                                percentageAttributes.forEach(pA => {
                                    //Check if attribute is filled
                                    if (!isEmptyObject(record.properties) && !isEmptyValue(record.properties[pA])) {
                                        filledCount++;
                                    }
                                });
                                thisPercent = Math.floor((filledCount / (percentageAttributes.length + 1)) * 100);
                                if (thisPercent === 100) {
                                    thisStatus = "success";
                                } else {
                                    thisStatus = "normal";
                                } //MANDATORY check
                                let mandatoryAttributes = eType.properties.attributes.filter(att => att.isRequired && ["string", "number", "boolean", "date"].includes(att.type)).map(a => a.techName);
                                mandatoryAttributes.forEach(mA => {
                                    //Check if mandatory attribute is missing
                                    if (isEmptyObject(record.properties) || (!isEmptyObject(record.properties) && isEmptyValue(record.properties[mA]))) {
                                        thisStatus = "exception";
                                        thisTitle = t('app.entry.view.lblEntryFillQualityMissingMandatory');
                                        return;
                                    }
                                });
                            }
                        }
                        else{
                            thisStatus = "exception";
                        }
                        
                        return <Progress title={thisTitle} percent={thisPercent} size="small" status={thisStatus} />;
                    }
                };

                columnList.push(fillQualityColumnDefinition);
            }
        }
    };

    //add all user defined columns from Entry properties if they are selected in this Folder setup
    addUserAttributes = (columnList, usedTypesInData, filteredTypes, t) => {
        if (!isEmptyValue(this.props.folderProperties) && !isEmptyValue(this.props.folderProperties.childrenDisplayAttributes)) {
            let attDefs = [];
            if (this.props.entryTypesRequestResult.getState().isDone() &&
                !isEmptyObject(this.props.entryTypesRequestResult.getData()) &&
                !isEmptyValue(this.props.folderProperties.childObjectEntryTypes)) {
                //filter only types in folder and types currently in data => dont display empty columns
                let eTypes = this.props.entryTypesRequestResult.getData().filter(eT =>
                    //types allowed in folder
                    this.props.folderProperties.childObjectEntryTypes.includes(eT.type) &&
                    //types in data
                    usedTypesInData.includes(eT.type) &&
                    //types filtered in table
                    (isEmptyValue(filteredTypes) ? true : filteredTypes.includes(eT.name))
                );
                eTypes.forEach(eT => {
                    if (!isEmptyValue(eT.properties) && !isEmptyValue(eT.properties.attributes)) {
                        eT.properties.attributes.forEach(att => {
                            if (this.props.folderProperties.childrenDisplayAttributes.includes(att.techName) &&
                                isEmptyObject(attDefs.find(a => a.techName === att.techName))) {
                                attDefs.push(att);
                            }
                        });
                    }
                });
            }
            console.log(this.props.childsDataList,'this.props.childsDataList');
            
            this.props.folderProperties.childrenDisplayAttributes.forEach(att => {
                let attName = att;
                let attType = "attribute";
                let attDef = attDefs.find(a => a.techName === att);
                if (!isEmptyObject(attDef)) {
                    attName = attDef.name;
                    attType = attDef.type;
                    if (attDef.type === "lov") {
                        columnList.push({ ...getColumnNameValueTag(["properties", att], attName, attDef, t) });
                    }else if(attDef.type !== "boolean"){
                        columnList.push({ ...getColumnCustomAttributePropsWithFilter(["properties", att], attName, attType, t,this.props.childsDataList) });
                    }else {
                        columnList.push({ ...getColumnCustomAttributeProps(["properties", att], attName, attType, t,) });
                    }
                }
            });
        }
    };

    //Renderer for Entry name column. Renders it as link with ability to highlight quick search results
    handleRenderTextColumn = (text, record, dataIndex) => {
        return this.state.searchedColumn === dataIndex ? (
            <Link to={`/entry/${record.id}`}>{highlightSearchedText(text, this.state.searchText)}</Link>
        ) : (
            <Link to={`/entry/${record.id}`}>{text}</Link>
        );
    };

    //set filters
    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };

    //reset filters
    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };

    showTotal = (total) => {
        return `(${total})`;
    };

    handlePageSizeChanged = (current, page) => {
        localStorage.setItem("childTablePageSize", page);
        this.setState({ childTablePageSize: page });
    };

    handleTableChange = (pagination, filters, sorter) => {
        console.log('Various parameters', filters, sorter);
        if (!isUndefined(filters.entryType) && !deepEqual(filters.entryType, this.state.filteredTypes)) {
            this.setState({ filteredTypes: filters.entryType })
        } else {
            if (isUndefined(filters.entryType) && !isEmptyValue(this.state.filteredTypes)) {
                this.setState({ filteredTypes: [] })
            }
        }
    };
    render() {
        const { t } = this.props;

        let columnsChilds = [
            {
                title: t('app.entry.view.tblEntriesName'),
                dataIndex: 'entry',
                key: 'entry',
                fixed: 'left',
                ...getColumnSearchProps('entry', this.handleSearch, this.handleReset, this.handleRenderTextColumn, t, this.searchInput),
            },
            {
                title: t('app.entry.view.tblEntriesType', t),
                dataIndex: 'entryType',
                key: 'entryType',
                filters: Array.from(new Set(this.props.childsDataList.map(itm => itm.entryType))).map(
                    itmName => {
                        return { text: itmName, value: itmName };
                    }
                ),
                onFilter: (value, record) => record.entryType === value
                // record.entryType.indexOf(value) === 0
            }
        ];

        let typesInDataArr = this.props.childsDataList.map(d => d.type);

        if (!isEmptyValue(this.props.folderProperties) && this.props.folderProperties.displayTechAttributesFirst) {
            this.addTechnicalAttributes(columnsChilds, t);
            this.addUserAttributes(columnsChilds, typesInDataArr, this.state.filteredTypes, t);
        } else {
            this.addUserAttributes(columnsChilds, typesInDataArr, this.state.filteredTypes, t);
            this.addTechnicalAttributes(columnsChilds, t);
        }

        this.addFillQualityColumn(columnsChilds, t);

        let paginationSetup = {
            showSizeChanger: true,
            size: "small",
            showTotal: this.showTotal,
            defaultPageSize: this.state.childTablePageSize,
            pageSizeOptions: ['10', '20', '30', '50', '100'],
            onShowSizeChange: this.handlePageSizeChanged
        };

        return <Table //title={() => <div className="childTableTitle">{t('app.entry.view.tblEntriesTitle')}</div>} 
            title={() => this.props.tableTitle}
            columns={columnsChilds}
            dataSource={this.props.childsDataList}
            size="small"
            pagination={paginationSetup}
            scroll={{ x: true }}
            onChange={this.handleTableChange}
            className='EntryChildTable'
        />;

    }
}

export default withTranslation()(EntryChildTable);

EntryChildTable.propTypes = {
    childsDataList: PropTypes.arrayOf(bmcEntryShape).isRequired,
    folderProperties: folderPropShape.isRequired,
    entryTypesRequestResult: entryTypesRequestResult.isRequired
};
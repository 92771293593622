import {createBaseOptions, fetchJson, METHOD_GET} from "../utils/SuperAgentFetch";
import {config} from "../config/Config";

export const callFullReindex = (callback) => {
    
    /** @type RequestOptions */
    let options = createBaseOptions();
    options.method = METHOD_GET;
    options.headers["Content-Type"] = "application/json";

    let r = fetchJson(`${config.url.API_URL}/bmcEntryDocs/custom/reindex`, options) // /async
    r.then(json => {
        console.log(json);
        callback(true);
    }).catch(error => {
        console.log(error);
        callback(false);
    });
};

export const callFolderReindex = (callback, id) => {
    
    /** @type RequestOptions */
    let options = createBaseOptions();
    options.method = METHOD_GET;
    options.headers["Content-Type"] = "application/json";

    let r = fetchJson(`${config.url.API_URL}/bmcEntryDocs/custom/reindex/${id}`, options) // /async
    r.then(json => {
        console.log(json);
        callback(true);
    }).catch(error => {
        console.log(error);
        callback(false);
    });
};
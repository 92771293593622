import React from 'react';
import { isUndefined, isEmptyObject, isEmptyValue, isArray } from "../../utils/JsObjectHelper";
import PropTypes from "prop-types";
import update from 'immutability-helper';
import { entryTypesRequestResult, settingResultDetailShape } from "../../shapes/RequestResult";
import LineageNewHierarchy from "./lineage/LineageNewHierarchy"
import LineageDetailForm from "./lineage/LineageDetailForm"
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next'
import { withRouter } from "react-router-dom";
import { Row, Col, PageHeader, Button, Modal, Table, Card, Checkbox, Statistic } from 'antd';
import { DeleteOutlined, SaveOutlined, EditOutlined, PlusOutlined, InfoCircleOutlined, FolderOpenOutlined } from '@ant-design/icons';
import { EMPTY_SETTINGS, EMPTY_SETTINGS_LINEAGE } from '../../utils/EmptySettings';
import debounce from 'lodash/debounce';
import { fetchLineageUsedInFolders } from '../../apicalls/fetchLineageUsedInFolders';
const cloneDeep = require('lodash.clonedeep');

class LineageDetail extends React.Component {

    formRef = React.createRef();

    constructor(props) {
        super(props);
        this.onMount = this.onMount.bind(this);
        this.onHandleSubmit = this.onHandleSubmit.bind(this);
        this.onHierarchyDelete = this.onHierarchyDelete.bind(this);
        this.onHierarchyEdit = this.onHierarchyEdit.bind(this);
        this.onHierarchyAddShow = this.onHierarchyAddShow.bind(this);
        this.onHandleHierarchyAdd = this.onHandleHierarchyAdd.bind(this);
        this.onHierarchyAddCancel = this.onHierarchyAddCancel.bind(this);

        this.changeButtonState = debounce(this.changeButtonState, 800);
        let initFormaData = EMPTY_SETTINGS;
        initFormaData.value = EMPTY_SETTINGS_LINEAGE;

        this.state = {
            disabledButton: false,
            formData: initFormaData,
            showAddNewHierarchy: false,
            currentEditRecord: null,
            usedInFolders: [],
            showUsedInFolders: false
        };
    }
    componentDidMount() {
        this.onMount();
    };

    /**
     * 
     * @param {*} prevProps 
     * @param {*} prevState 
     */
    componentDidUpdate(prevProps, prevState) {
        if (!isEmptyObject(this.props.settingsDetailData) && isEmptyObject(prevProps.settingsDetailData)) {
            this.setState({ formData: cloneDeep(this.props.settingsDetailData) });
        }
    }

    onMount() {
        this.props.onMount(this.props.match.params.setupDetailId);
        fetchLineageUsedInFolders(this.props.match.params.setupDetailId, (folders) => {
            this.setState({ usedInFolders: folders });
        });
    };

    /**
     * Remove selected hierarchy from state formData
     * @param {Object} hierarchyRecord 
     */
    onHierarchyDelete = (hierarchyRecord) => {
        this.setState((prevState, props) => {
            return update(prevState, {
                formData: {
                    value: {
                        hierarchy: h => h.filter(item => !(item.parent === hierarchyRecord.parent && item.relation === hierarchyRecord.relation && item.child === hierarchyRecord.child))
                    }
                }
            });
        }
        );
    };

    /**
     * 
     */
    onHierarchyAddShow = () => {
        this.setState({ showAddNewHierarchy: true, currentEditRecord: null });
    };

    /**
     * 
     * @param {Object} hierarchyRecord 
     */
    onHierarchyEdit = (hierarchyRecord) => {
        this.setState({ showAddNewHierarchy: true, currentEditRecord: cloneDeep(hierarchyRecord) });
    };

    /**
     * 
     */
    onHierarchyAddCancel = () => {
        this.setState({ showAddNewHierarchy: false, currentEditRecord: null });
    };

    /**
     * 
     * @param {Object} hierarchyRecord 
     */
    onHandleHierarchyAdd = (hierarchyRecord) => {
        this.setState({ showAddNewHierarchy: false });
        if (!isEmptyValue(hierarchyRecord) && !isEmptyValue(hierarchyRecord.parent) && !isEmptyValue(hierarchyRecord.child) && !isEmptyValue(hierarchyRecord.relation)) {
            if (!isEmptyObject(this.state.currentEditRecord)) {
                //Save edited record
                let hierarchyData = cloneDeep(this.state.formData.value.hierarchy);
                for (var i = hierarchyData.length - 1; i >= 0; --i) {
                    if (hierarchyData[i].parent === this.state.currentEditRecord.parent && hierarchyData[i].relation === this.state.currentEditRecord.relation && hierarchyData[i].child === this.state.currentEditRecord.child) {
                        hierarchyData.splice(i, 1, hierarchyRecord);
                        break;
                    }
                }

                this.setState((prevState, props) => {
                    return update(prevState, {
                        formData: {
                            value: {
                                hierarchy: { $set: hierarchyData }
                            }
                        },
                        currentEditRecord: { $set: null }
                    });
                });
            } else {
                //Add new record
                this.setState((prevState, props) => {
                    return update(prevState, {
                        formData: {
                            value: {
                                hierarchy: { $push: [hierarchyRecord] }
                            }
                        }
                    });
                });
            }
        }
    };

    /**
     * 
     * @param {*} formInputs 
     */
    onHandleFormChange = (formInputs) => {
        formInputs.forEach((field) => {
            let fieldName = (isArray(field.name) ? field.name.at(-1) : field.name);
            let fieldValue = field.value;
            this.setState((prevState, props) => {
                return update(prevState, {
                    formData: {
                        value: {
                            [fieldName]: { $set: fieldValue }
                        }
                    }
                });
            });
        });
    };

    /**
     * 
     */
    onHandleSubmit = () => {
        this.formRef.current.validateFields()
            .then(values => {
                this.props.onSaveSettings(this.state.formData);
            })
            .catch(errorInfo => {
                console.log(errorInfo);
                return;
            });
    };
    changeButtonState = (disabled) => {
        this.setState({ disabledButton: disabled });
    }
    onShowUsedInFolders = () => {
        this.setState({ showUsedInFolders: true });
    }
    onCloseUsedInFolders = () => {
        this.setState({ showUsedInFolders: false });
    }
    render() {
        const { t } = this.props;

        let info = () => {
            Modal.info({
                title: t('setup.lineageSetup.infoTitleDetail'),
                content: (
                    <div>
                        {t('setup.lineageSetup.infoContentDetail')}
                    </div>
                ),
                onOk() {
                },
            });
        };
        let columnsUsedInFolders = [
            {
                title: 'Name',
                dataIndex: 'name',
                key: 'name',
                render: (text, record) => {
                    return <Link to={`/entry/${record.id}`}>{record.name}</Link>;
                }
            },
        ];
        let modalUsedInFolders = <Modal 
        title={t('setup.lineageSetup.detailUsedInFolders')} 
            onCancel={this.onCloseUsedInFolders}
            visible={this.state.showUsedInFolders}
            footer={false}>
            <Table dataSource={this.state.usedInFolders} columns={columnsUsedInFolders} pagination={false} />
        </Modal>;

        let hierarchyData = (isEmptyObject(this.state.formData) ? [] : this.state.formData.value.hierarchy);

        let getEntryTypeName = (type) => {
            if (this.props.objectListRequestResult.getState().isDone() && !isEmptyObject(this.props.objectListRequestResult.getData())) {
                let foundType = this.props.objectListRequestResult.getData().find(eT => eT.type === type);
                if (!isUndefined(foundType)) {
                    return foundType.name;
                }
            }
            return type;
        };

        let getEntryRelationName = (hierarchyRecord) => {
            if (this.props.objectListRequestResult.getState().isDone() && !isEmptyObject(this.props.objectListRequestResult.getData())) {
                let foundType = this.props.objectListRequestResult.getData().find(eT => eT.type === hierarchyRecord.parent);
                if (!isUndefined(foundType) && !isUndefined(foundType.properties.attributes)) {
                    let rel = foundType.properties.attributes.find(at => at.techName === hierarchyRecord.relation);
                    if (!isUndefined(rel)) {
                        return rel.name;
                    }
                }
            }
            return hierarchyRecord.relation;
        };

        const columns = [
            {
                title: '',
                dataIndex: 'action',
                width: '70px',
                render: (text, record) => {
                    return [
                        <Button
                            key="delBtn"
                            onClick={() => this.onHierarchyDelete(record)}
                            icon={<DeleteOutlined />} size="small" type="danger" ghost></Button>,
                        <Button
                            key="editBtn"
                            onClick={() => this.onHierarchyEdit(record)}
                            icon={<EditOutlined />} size="small"></Button>
                    ];
                }
            },
            {
                title: t('setup.lineageSetup.detailTblParetn'),
                dataIndex: 'parent',
                key: 'parent',
                render: (text, record) => {
                    return `${getEntryTypeName(text)} (${text})`;
                }
            },
            {
                title: t('setup.lineageSetup.detailTblRelation'),
                dataIndex: 'relation',
                key: 'relation',
                render: (text, record) => {
                    return `${getEntryRelationName(record)} (${text})`;
                }
            },
            {
                title: t('setup.lineageSetup.detailTblChild'),
                dataIndex: 'child',
                key: 'child',
                render: (text, record) => {
                    return `${getEntryTypeName(text)} (${text})`;
                }
            },
            {
                title: <span title={t('setup.lineageSetup.detailDoExpandParent')}>{t('setup.lineageSetup.detailTblDoExpandParent')}</span>,
                dataIndex: 'doExpandParent',
                key: 'doExpandParent',
                render: (text, record) => {
                    return <Checkbox checked={text} disabled={true}></Checkbox>;
                }
            },
        ];

        /** @type {RequestResult} */
        const rr = this.props.settingsDetailRequestResult;
        if (isEmptyObject(rr) || rr.getState().isLoading())
            return <div>{t('app.entry.header.msgLoading')}</div>;
        else if (isEmptyObject(rr.getData()))
            return <div>{t('app.entry.header.msgEntryNonFound')}</div>;
        else {

            return <div>
                {modalUsedInFolders}
                <PageHeader
                    title={t('setup.lineageSetup.headerDetail')}
                    subTitle={t('setup.lineageSetup.headerDetailLabel')}
                    extra={[
                        <Button key="info-circle-button-key" icon={<InfoCircleOutlined />} /* type="primary" */ onClick={info}>
                        </Button>,
                    ]}
                >
                </PageHeader>
                <div className="steps-content">
                    <Row align="top" gutter={[20, 20]}>
                        <Col span={24}>
                            <Statistic title={t('setup.lineageSetup.detailUsedInFolders')} value={this.state.usedInFolders.length} prefix={<FolderOpenOutlined />} />
                            <Button type="primary" onClick={() => this.onShowUsedInFolders()}>{t('setup.lineageSetup.detailBtnShowUsedInFolders')}</Button>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={10}>
                            <Card>
                                <LineageDetailForm
                                    lineageValueData={this.state.formData.value}
                                    lineageSettingsList={this.props.lineageSettingsList}
                                    activeId={this.props.match.params.setupDetailId}
                                    changeButtonState={this.changeButtonState}
                                    //wrappedComponentRef={this.saveFormRef}
                                    extFormRef={this.formRef}
                                    onAttributeChange={this.onHandleFormChange}
                                    objectListRequestResult={this.props.objectListRequestResult}
                                >
                                </LineageDetailForm>
                            </Card>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={14}>
                            <Card bodyStyle={{ padding: '10px' }}>
                                <Table
                                    size="small"
                                    dataSource={hierarchyData}
                                    columns={columns}
                                    pagination={false}
                                    style={{ backgroundColor: 'white' }}
                                    title={() => <span style={{ textAlign: 'center', width: '100%', display: 'table' }}><b>{t('setup.lineageSetup.detailTblTitle')}</b></span>}
                                    footer={() => <Button icon={<PlusOutlined />} size="small" onClick={this.onHierarchyAddShow}>{t('setup.lineageSetup.detailTblAddHierarchy')}</Button>}
                                >

                                </Table>
                            </Card>
                        </Col>
                    </Row>
                </div>
                <Row className="steps-action" justify="end" type="flex">
                    <Col>
                        <Button icon={<SaveOutlined />} disabled={this.state.disabledButton} type="primary" onClick={this.onHandleSubmit}>
                            {this.props.isCreateNew ? t('setup.lineageSetup.detailBtnCreate') : t('setup.lineageSetup.detailBtnSave')}
                        </Button>
                    </Col>
                </Row>
                <LineageNewHierarchy
                    isVisible={this.state.showAddNewHierarchy}
                    objectListRequestResult={this.props.objectListRequestResult}
                    onAddHierarchy={this.onHandleHierarchyAdd}
                    onCancel={this.onHierarchyAddCancel}
                    editingEntryData={this.state.currentEditRecord}
                ></LineageNewHierarchy>
            </div>;
        }
    }
}

const LineageDetailWithRouter = withRouter(LineageDetail);
export default withTranslation()(LineageDetailWithRouter);

LineageDetail.propTypes = {
    settingsDetailRequestResult: settingResultDetailShape.isRequired,
    //settingsDetailData: PropTypes.arrayOf(Object),
    objectListRequestResult: entryTypesRequestResult.isRequired,
    onSaveSettings: PropTypes.func.isRequired

};
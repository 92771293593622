import React from 'react';
import { Input, Collapse, Checkbox, Select, InputNumber, Button, Form, Divider, Space } from "antd";
import { DeleteOutlined, CaretRightOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
//import {attributeDataShape} from "../../shapes/AttributeShapes";
import { entryTypesRequestResult } from "../../shapes/RequestResult";
import { isEmptyValue, isUndefined } from "../../utils/JsObjectHelper";
import { fetchEntries } from '../../apicalls/fetchEntries';
import debounce from 'lodash/debounce';
import { withTranslation } from 'react-i18next'
import FormNameValueItem from '../controls/FormNameValueItem';

const { Panel } = Collapse;
const { Option } = Select;

const cloneDeep = require('lodash.clonedeep');

class AttributeForm extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            searchableInputData: [],
            searchableInputValue: [],
            searchableInputFetching: false,
        };
        this.fetchFolders = this.fetchFolders.bind(this);
        this.fetchFolders = debounce(this.fetchFolders, 800);
    }

    decideSearchableEnabled() {
        if (!isUndefined(this.props.extFormRef.current)) {
            let currentType = this.props.extFormRef.current.getFieldValue(["attributes", this.props.attributeData.name, "type"]);
            return !(currentType === "fileUrlArray" || currentType === "relation" || currentType === "hyperlink" /*|| currentType === "lov"*/);
        }
        return false;
    }

    fetchFolders(value) {
        fetchEntries(value, "fetchFolders", searchData => this.setState({ searchableInputData: searchData }), null, ["folder"]);
    }

    fetchSelectedFolders(idArr) {
        //console.log('LOADING FOLDERS');
        //console.log(idArr);
        if (!isUndefined(idArr) && idArr.length > 0) {
            let searchQuery = "";
            idArr.forEach((id) => {
                searchQuery += "id:" + id + " ";
            });
            fetchEntries(searchQuery, "fetchSelectedFolders", searchData => this.setState({ searchableInputValue: searchData }), null, ["folder"]);
        }
    }

    /**
     * Validator for attributes technical name. It is forbidden to have same technames of more attributes in one entrytype.
     * 
     * @param {*} rule 
     * @param {*} value 
     * @param {*} callback 
     */
    attributeTechnicalNameValidator = (rule, value, callback) => {
        if (!isUndefined(this.props.extFormRef.current)) {
            if (this.props.extFormRef.current.getFieldValue(["attributes"])
                .find((att, idx) => { return att.techName === value && idx !== this.props.index; }) != null
            ) {
                callback(rule.message);
            }
        }
        callback();
    };
    attributeNameValidator = (rule, value, callback) => {
        if (/^[A-Za-z0-9-_]+$/g.test(value)) {
            callback();
        } else {
            callback(rule.message);
        }
    };


    /**
     * Validator for attributes technical name. Checks for reserved technical names.
     * Not sure if it is really necessary :/
     * 
     * @param {*} rule 
     * @param {*} value 
     * @param {*} callback 
     */
    attributeTechnicalNameReservedNamesValidator = (rule, value, callback) => {
        if (!isUndefined(this.props.extFormRef.current)) {
            if (["id", "type", "name", "description"].includes(value)) {
                callback(rule.message);
            }
        }
        callback();
    };

    /**
     * Keep title with value. Title is for long names so users can see whole name at least in title.
     * 
     * @param {*} e 
     */
    inputChangeTitle = (e) => {
        e.target.title = e.target.value;
    };

    /**
     * Type change to non searchable types must reset isSearchable attribute
     */
    handleTypeChange = (type) => {
        if (!isUndefined(this.props.extFormRef.current) && (type === "fileUrlArray" || type === "relation")) {
            let formData = cloneDeep(this.props.extFormRef.current.getFieldsValue());
            formData.attributes[this.props.attributeData.name].isSearchable = false;
            this.props.extFormRef.current.setFieldsValue(formData);
        }
    };

    /**
     * Returns array of option setups for Select of Attribute types
     * 
     * @param {string} typeName 
     * @returns 
     */
    constructTypeOptions = (typeName) => {
        const { t } = this.props;

        let optionsArr = [
            { label: t('setup.entryTypes.detail.attTypeString'), value: "string", disabled: false },
            { label: t('setup.entryTypes.detail.attTypeLocString'), value: "localizedString", disabled: false },
            { label: t('setup.entryTypes.detail.attTypeHyperlink'), value: "hyperlink", disabled: false },
            { label: t('setup.entryTypes.detail.attTypeNumber'), value: "number", disabled: false },
            { label: t('setup.entryTypes.detail.attTypeDate'), value: "date", disabled: false },
            { label: t('setup.entryTypes.detail.attTypeRelation'), value: "relation", disabled: false },
            { label: t('setup.entryTypes.detail.attTypeBoolean'), value: "boolean", disabled: false },
            { label: t('setup.entryTypes.detail.attTypeLoV'), value: "lov", disabled: false },
            { label: t('setup.entryTypes.detail.attTypeFile'), value: "fileUrlArray", disabled: false }
        ];

        if (!this.props.isNew) {
            optionsArr.forEach(opt => {
                if (typeName !== opt.value) {
                    opt.disabled = true;
                    if ((typeName === "number" || typeName === "date" || typeName === "boolean") && opt.value === "string")
                        opt.disabled = false;
                }
            });
        }

        return optionsArr;
    };

    /**
     * 
     * 
     * @returns FormItems according to Attribute type
     */
    constructTypeRelevantValues = () => {
        const { t, i18n } = this.props;
        let typeRelevantValues = [];
        if (!isUndefined(this.props.extFormRef.current)) {
            switch (this.props.extFormRef.current.getFieldValue(["attributes", this.props.attributeData.name, "type"])) {
                case "string":
                    typeRelevantValues.push(
                        this.addFormInputNumberItem(i18n, [this.props.attributeData.name, "minLength"], t('setup.entryTypes.detail.attMinlength'), t('setup.entryTypes.detail.attMinlength'), [])
                    );
                    typeRelevantValues.push(
                        this.addFormInputNumberItem(i18n, [this.props.attributeData.name, "maxLength"], t('setup.entryTypes.detail.attMaxlength'), t('setup.entryTypes.detail.attMaxlength'), [])
                    );
                    break;
                case "localizedString":
                    typeRelevantValues.push(
                        this.addFormInputNumberItem(i18n, [this.props.attributeData.name, "minLength"], t('setup.entryTypes.detail.attMinlength'), t('setup.entryTypes.detail.attMinlength'), [])
                    );
                    typeRelevantValues.push(
                        this.addFormInputNumberItem(i18n, [this.props.attributeData.name, "maxLength"], t('setup.entryTypes.detail.attMaxlength'), t('setup.entryTypes.detail.attMaxlength'), [])
                    );
                    let optionsLanguages = Object.keys(i18n.options.resources).map((el) => <Option value={el} disabled={el === 'en' ? true : false} key={el}>{el}</Option>,)
                    typeRelevantValues.push(
                        this.addFormSelectItem([this.props.attributeData.name, "selectedLanguages"], optionsLanguages, "Languages", "Select languages", [{ required: false, message: t('setup.entryTypes.detail.attRelEntryParentErr') }], 300, "multiple",)
                    )
                    break;
                case "number":
                    typeRelevantValues.push(
                        this.addFormInputNumberItem(i18n, [this.props.attributeData.name, "minLength"], t('setup.entryTypes.detail.attMinvalue'), t('setup.entryTypes.detail.attMinvalue'), [])
                    );
                    typeRelevantValues.push(
                        this.addFormInputNumberItem(i18n, [this.props.attributeData.name, "maxLength"], t('setup.entryTypes.detail.attMaxvalue'), t('setup.entryTypes.detail.attMaxvalue'), [])
                    );
                    typeRelevantValues.push(
                        this.addFormInputItem([this.props.attributeData.name, "displayFormat"], t('setup.entryTypes.detail.attDisplayunit'), t('setup.entryTypes.detail.attDisplayunit'), [], 120)
                    );
                    break;
                case "date":
                    typeRelevantValues.push(
                        this.addFormInputItem([this.props.attributeData.name, "displayFormat"], t('setup.entryTypes.detail.attDisplayformat'), t('setup.entryTypes.detail.attDisplayformat'), [])
                    );
                    break;
                case "relation":
                    // we don't check the loading status, we show always data, if we have it
                    if (!isUndefined(this.props.entryTypesRequestResult.data) && !isEmptyValue(this.props.entryTypesRequestResult.data)) {
                        let entryTypeOptions = this.props.entryTypesRequestResult.data.map(d => <Option value={d.type} key={d.type}>{d.name}</Option>);

                        typeRelevantValues.push(
                            this.addFormSelectItem([this.props.attributeData.name, "relationEntryType"], entryTypeOptions, t('setup.entryTypes.detail.attRelEntryType'), t('setup.entryTypes.detail.attRelEntryType'), [{ required: true, message: t('setup.entryTypes.detail.attRelEntryTypeErr') }], 300, "multiple")
                        );

                        let entryFolderOprions = this.state.searchableInputValue.map(d => <Option value={d.id} key={d.id}>{d.name}</Option>);
                        let searchedOptions = this.state.searchableInputData.filter(e => !this.state.searchableInputValue.find(v => v.id === e.id)).map(d => <Option value={d.id} key={d.id}>{d.name}</Option>);
                        //get only distinct options
                        entryFolderOprions = entryFolderOprions.concat(searchedOptions);

                        let parentIds = [];
                        if (!isUndefined(this.props.extFormRef.current)) {
                            parentIds = this.props.extFormRef.current.getFieldValue(["attributes", this.props.attributeData.name, "relationEntryParent"]);
                        }
                        if (isEmptyValue(this.state.searchableInputValue)) {
                            this.fetchSelectedFolders(parentIds);
                        }

                        typeRelevantValues.push(
                            this.addFormSelectSearchableItem([this.props.attributeData.name, "relationEntryParent"], entryFolderOprions, t('setup.entryTypes.detail.attRelEntryParent'), t('setup.entryTypes.detail.attRelEntryParent'), [{ required: false, message: t('setup.entryTypes.detail.attRelEntryParentErr') }], 300, "multiple", this.fetchFolders)
                        );
                    }
                    break;
                case "fileUrlArray":
                    let filePictureSelector = [
                        <Option value="file" key="file">{t('setup.entryTypes.detail.attFile')}</Option>,
                        <Option value="image" key="image">{t('setup.entryTypes.detail.attImg')}</Option>
                    ];
                    typeRelevantValues.push(
                        this.addFormSelectItem([this.props.attributeData.name, "contentType"], filePictureSelector, t('setup.entryTypes.detail.attContentType'), t('setup.entryTypes.detail.attContentType'), [{ required: true, message: t('setup.entryTypes.detail.attContentTypeErr') }], 300)
                    );
                    break;
                case "lov":
                    //TODO: dodelat nastaveni LoV

                    typeRelevantValues.push(
                        <div>
                            {this.addFormCheckBoxItem([this.props.attributeData.name, "isMultiple"], t('setup.entryTypes.detail.attMultiple'), t('setup.entryTypes.detail.attMultiple'), [], this.props.attributeData.isMultiple)}
                            <Form.Item label={t('setup.entryTypes.detail.attDisplayformat')}
                                name={[this.props.attributeData.name, "lovArray"]}
                                key={[this.props.attributeData.name, "lovArray"]}
                            >
                                <FormNameValueItem useColor={true} useTypes={false}></FormNameValueItem>
                            </Form.Item>
                        </div>

                    );

                    break;
                case "hyperlink":
                default:
                    break;
            }
        }

        return typeRelevantValues;
    };

    render() {
        const { t } = this.props;

        let alterClassName = (this.props.index % 2 === 0 ? '' : 'alterAttrFormRow');

        //dodelat aby byl nove vytvoreny atribut otevreny
        let expandedPanel = (this.props.isNew ? ['1'] : []);

        let titleName = "";
        let titleTechname = "";
        let selectedAttType = null;
        let attributeDef = null;
        if (!isUndefined(this.props.extFormRef.current)) {
            titleName = this.props.extFormRef.current.getFieldValue(["attributes", this.props.attributeData.name, "name"]);
            titleTechname = this.props.extFormRef.current.getFieldValue(["attributes", this.props.attributeData.name, "techName"]);
            selectedAttType = this.props.extFormRef.current.getFieldValue(["attributes", this.props.attributeData.name, "type"]);
            attributeDef = this.props.extFormRef.current.getFieldValue(["attributes", this.props.attributeData.name]);
        }
        let typeOptions = this.constructTypeOptions(selectedAttType);
        return (
            <div className="attributeFormRow" /*style={{width: '100%'}}*/>
                <section>
                    <aside className={alterClassName}>
                        <div>
                            <span>{this.props.index + 1}</span>
                            {this.props.isNew && <Button onClick={() => this.props.onAttributeRemove(this.props.index)} icon={<DeleteOutlined />} size="small" type="danger" style={{ marginLeft: '17px' }}></Button>}
                        </div>
                    </aside>
                    <main>
                        <Space align='start' wrap size="1px">
                            {/* NAME */}
                            {this.addFormInputItem([this.props.attributeData.name, "name"], t('setup.entryTypes.detail.attName'), t('setup.entryTypes.detail.attName'), [{ required: true, message: t('setup.entryTypes.detail.attNameErr') }], 200, false, titleName)}
                            {/* TECHNICAL NAME */}
                            {this.addFormInputItem([this.props.attributeData.name, "techName"], t('setup.entryTypes.detail.attTechName'), t('setup.entryTypes.detail.attTechName'),
                                [
                                    { required: true, message: t('setup.entryTypes.detail.attTechNameErr') },
                                    { validator: this.attributeTechnicalNameValidator, message: t('setup.entryTypes.detail.attTechNameDuplaErr') },
                                    { validator: this.attributeNameValidator, message: t('setup.entryTypes.detail.validateMessageTechnicalName') },
                                    //{ validator: this.attributeTechnicalNameReservedNamesValidator, message: t('setup.entryTypes.detail.attTechNameReservedErr') }
                                ],
                                150, !this.props.isNew, titleTechname)}
                            {/* {this.addFormSelectItem([this.props.attributeData.name, "type"], typeOptions, t('setup.entryTypes.detail.attType'), t('setup.entryTypes.detail.attType'), [{ required: true, message: t('setup.entryTypes.detail.attTypeErr') }], 150)} */}
                            {/* TYPE */}
                            <Form.Item key={[this.props.attributeData.name, "type"]} label={t('setup.entryTypes.detail.attType')}
                                name={[this.props.attributeData.name, "type"]}
                                rules={[{ required: true, message: t('setup.entryTypes.detail.attTypeErr') }]}
                            >
                                <Select placeholder={t('setup.entryTypes.detail.attType')} style={{ width: 150 }}
                                    // optionFilterProp="children"
                                    onChange={this.handleTypeChange}
                                    options={typeOptions}
                                >
                                </Select>
                            </Form.Item>
                            {/* IS REQUIRED */}
                            {this.addFormCheckBoxItem([this.props.attributeData.name, "isRequired"], t('setup.entryTypes.detail.attMandatory'), t('setup.entryTypes.detail.attMandatory'), [])}
                            {/* IS SEARCHABLE */}
                            <Form.Item
                                noStyle
                                shouldUpdate
                            >
                                {() => (<>
                                    {this.addFormCheckBoxItem([this.props.attributeData.name, "isSearchable"],
                                        t('setup.entryTypes.detail.attSearchable'),
                                        t('setup.entryTypes.detail.attSearchable'),
                                        [],
                                        !this.decideSearchableEnabled()
                                    )}
                                </>
                                )}
                            </Form.Item>
                        </Space>
                        <Divider dashed style={{ margin: '10px 0 0 0' }} />
                        <Collapse
                            bordered={true}
                            defaultActiveKey={expandedPanel}
                            expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                            style={{ marginTop: '10px', width: '100%' }}
                        >
                            <Panel header={t('setup.entryTypes.detail.lblTypeInfo')} key="1">
                                <Form.Item
                                    noStyle
                                    shouldUpdate
                                //={(prevValues, curValues) => prevValues.type !== curValues.type}
                                >
                                    {() => (
                                        <>
                                            <Space align='start' wrap>
                                                {this.constructTypeRelevantValues()}
                                            </Space>
                                            {this.addTooltips(this.props.attributeData.name)}</>
                                    )}
                                </Form.Item>
                            </Panel>
                        </Collapse>
                    </main>
                </section>
            </div>
        );
    }

    addTooltips(name) {
        const { t } = this.props
        return <Space style={{ width: '100%', marginTop: '16px' }} nowrap='true' direction="vertical">
            <Form.Item key={name + 'tooltipEdit'} label={t('setup.entryTypes.detail.labelTooltipEdit')}
                name={[name, 'tooltipEdit']}
            >
                <Input style={{ width: "100%" }} allowClear={true} />
            </Form.Item>
            <Form.Item key={name + 'tooltipView'} label={t('setup.entryTypes.detail.labelTooltipView')}
                name={[name, 'tooltipView']}
            >
                <Input style={{ width: "100%" }} allowClear={true} />
            </Form.Item>
        </Space>;
    }

    /**
     *
     * @param {String} name
     * @param {String} label
     * @param {String} placeholder
     * @param {Array<Object>} rules
     * @param {Number} inputWidth
     * @param {Boolean} isDisabled 
     * @return {*}
     */
    addFormInputItem(name, label = null, placeholder = '', rules = [], inputWidth = 300, isDisabled = false, initialTitle = "") {
        return <Form.Item key={name} label={label}
            name={name}
            rules={rules}
        >
            <Input title={initialTitle} placeholder={placeholder} style={{ width: inputWidth }} allowClear={true} disabled={isDisabled} onChange={this.inputChangeTitle} />
        </Form.Item>;
    }

    /**
     * 
     * @param {i18n instance} i18n 
     * @param {String} name
     * @param {String} label
     * @param {String} placeholder
     * @param {Array<Object>} rules
     * @param {Number} inputWidth
     * @param {Boolean} isDisabled 
     * @returns 
     */
    addFormInputNumberItem(i18n, name, label = null, placeholder = '', rules = [], inputWidth = 150, isDisabled = false) {
        return <Form.Item key={name} label={label}
            name={name}
            rules={rules}
        >
            <InputNumber style={{ width: inputWidth }} disabled={isDisabled}
                decimalSeparator={(1.1).toLocaleString(i18n.language).replace(/1/g, '')} />
        </Form.Item>;
    }

    /**
     *
     * @param {String} name
     * @param {String} label
     * @param {String} placeholder
     * @param {Array<Object>} rules
     * @param {Boolean} isDisabled 
     * @return {*}
     */
    addFormCheckBoxItem(name, label = null, placeholder = '', rules = [], isDisabled = false) {
        return <Form.Item key={name} label={label}
            name={name}
            rules={rules}
            valuePropName='checked'
        >
            <Checkbox placeholder={placeholder} disabled={isDisabled} /*style={{ width: 300 }}*/ />
        </Form.Item>;
    }

    /**
     *
     * @param {String} name
     * @param {Array<Option>} options
     * @param {String} label
     * @param {String} placeholder
     * @param {Array<Object>} rules
     * @param {Number} inputWidth
     * @param {String} inputMode
     * @return {*}
     */
    addFormSelectItem(name, options, label = null, placeholder = '', rules = [], inputWidth = 300, inputMode = null) {
        return <Form.Item key={name} label={label}
            name={name}
            rules={rules}
        >
            <Select placeholder={placeholder} style={{ width: inputWidth }} mode={inputMode} optionFilterProp="children">
                {options}
            </Select>
        </Form.Item>;
    }

    addFormSelectSearchableItem(name, options, label = null, placeholder = '', rules = [], inputWidth = 300, inputMode = null, onSearch) {
        return <Form.Item key={name} label={label}
            name={name}
            rules={rules}
        >
            <Select
                placeholder={placeholder}
                style={{ width: inputWidth }}
                mode={inputMode}
                optionFilterProp="children"
                onSearch={onSearch}
                filterOption={false}
            >
                {options}
            </Select>
        </Form.Item>;
    }
}

export default withTranslation()(AttributeForm);

AttributeForm.propTypes = {
    extFormRef: PropTypes.any.isRequired,
    index: PropTypes.number.isRequired,
    isNew: PropTypes.bool.isRequired,
    attributeData: PropTypes.object,
    onAttributeRemove: PropTypes.func,
    entryTypesRequestResult: entryTypesRequestResult.isRequired
};
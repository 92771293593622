import { notificationError } from "../../utils/NotificationsHelper";

export const fetchTranslate = async (apiUrl, apiKey, target, description, callback = false, tags) => {
    try {
        const response = await fetch(`${apiUrl}/translate`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                q: description,
                source: 'auto',
                target: target,
                format: "html",
                api_key: apiKey
            })
        });

        const data = await response.json();

        if (!callback) {
            console.log(data);
            return data.translatedText; 
        }

        callback(data);
    } catch (error) {
        return false;
        console.error('Error:', error);
    }
};


import {createBaseOptions, METHOD_DELETE} from "../utils/SuperAgentFetch";
import {config} from "../config/Config";

export const fetchDeleteFile = (id,callback) => {
    
        
    /** @type RequestOptions */
    let options = {
        mode: "cors",
        method: 'DELETE',
        headers: {
          "Content-Type": "application/json",
          Authorization: 'Bearer ' + sessionStorage.getItem(`kcToken-${config.keycloak.url.replace(/(^\w+:|^)\/\//, '')}`)
        }
    };

     fetch(`${config.url.FILES_URL}/file/${id}`, options)
    .then(response => {
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        callback(response.ok); 
    }).catch(error => {
        console.log(error);
        callback(false);
    });
};


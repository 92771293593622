import { config } from "../config/Config";

/**
 * Downloads file from authorized API.
 * 
 * @param {string} downloadUrl 
 * @param {string} fileName 
 */
export const downloadFile = (downloadUrl, fileName,callback) => {
    let token = sessionStorage.getItem(`kcToken-${config.keycloak.url.replace(/(^\w+:|^)\/\//, '')}`);
    if(token) {
        fetch(downloadUrl, {
            method: 'GET',
            headers: {
                Authorization: 'Bearer ' + token
            },
        })
        .then((response) => response.blob())
        .then((blob) => {
            // Create blob link to download
            const url = window.URL.createObjectURL(
                new Blob([blob]),
            );
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute(
                'download',
                fileName,
            );
            // Append to html link element page
            document.body.appendChild(link);
            // Start download
            link.click();
            // Clean up and remove the link
            link.parentNode.removeChild(link);
            if(callback){
                callback(true);
            }
        });
    } else {
        console.error('Cannot get token!');
        if(callback){
            callback(false);
        }
    }
};
export const EMPTY_GENERAL_SETTINGS_DEFINITION = { 
    contextEngine: {
        depth: 5
    },
    folderDefaults: {
        subFolderDisplayType: "icons",
        childrenDisplayType: "table",
        childrenDisplayTechAttr: [],
        displayTechAttributesFirst: false
    },
    limits: {
        loadRelationAttDetailLimit: 100,
        displayRelationTagLimit: 100,
        hierarchySubTreeLimit: 100
    },
    autoTranslate: {
        active: false,
        apiUrl: null,
        apiKey: null
    }   
};
import React from "react";
import { CloudDownloadOutlined, InboxOutlined, InfoCircleOutlined, HighlightOutlined, FolderOpenOutlined, CloseOutlined } from '@ant-design/icons';
import { PageHeader, Button, Upload, Modal, Spin, message, Empty, Tabs, Switch, List, Select, Alert } from 'antd';
import { withTranslation } from 'react-i18next'
import { downloadFile } from "../../../utils/FileHelper";
import { config } from "../../../config/Config";
import { notificationError } from "../../../utils/NotificationsHelper";
import debounce from 'lodash/debounce';
import { fetchEntries } from "../../../apicalls/fetchEntries";
import { isUndefined } from "../../../utils/JsObjectHelper";
import { isMobile, isTablet } from "../../../utils/AdaptiveLayout";
const { Dragger } = Upload;
const { Option } = Select;
class BackupRestore extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            overwriteExisting: false,
            fetchedFolders: [],
            fetching: false,
            folderId: null,
            selectedFolder: null
        }
        this.onFolderSearch = this.onFolderSearch.bind(this);
        this.onFolderSearch = debounce(this.onFolderSearch, 800);
        this.onFolderFetched = this.onFolderFetched.bind(this);
        this.onFolderSelected = this.onFolderSelected.bind(this);
    }

    downloadBackup = () => {
        this.setState({ loading: true });
        const siteName = window.location.hostname;

        const now = new Date();
        const dateString = now.toLocaleDateString('en-GB', {
            day: '2-digit', month: '2-digit', year: 'numeric'
        }).replace(/\//g, '.');

        const hours = now.getHours() < 10 ? `0${now.getHours()}` : now.getHours();
        const minutes = now.getMinutes() < 10 ? `0${now.getMinutes()}` : now.getMinutes();


        const fileName = `${siteName}-backup-${dateString}-${hours}h${minutes}m.zip`;

        downloadFile(`${config.url.API_URL}/backup/files`, fileName, (loadState) => {
            if (loadState) {
                this.setState({ loading: false });
            } else {
                notificationError("Error");
            }
        });
    }
    handleFileUpload = (options) => {
        const { t } = this.props;
        const { file } = options;
        const formData = new FormData();
        formData.append('file', file);
        formData.append('overwriteExisting', this.state.overwriteExisting);
        this.setState({ loading: true });
        let token = sessionStorage.getItem(`kcToken-${config.keycloak.url.replace(/(^\w+:|^)\/\//, '')}`);
        fetch(`${config.url.API_URL}/restore/files`, {
            method: 'POST',
            body: formData,
            headers: {
                Authorization: 'Bearer ' + token
            },
        })
            .then(response => response.json())
            .then(data => {
                this.setState({ loading: false, overwriteExisting: false });
                let errorList = data.errorFileNames.length > 0 ? <List
                    size="small"
                    dataSource={data.errorFileNames}
                    renderItem={(item) => <List.Item style={{ color: 'red' }}>{item}</List.Item>}
                /> : null;
                let responseMessage = <div>
                    <p>{t('setup.backupRestore.filesCreated') + data.filesCreatedCount}</p>
                    <p>{t('setup.backupRestore.overwrite') + data.filesOverwrittenCount}</p>
                    <p>{t('setup.backupRestore.skipped') + data.filesSkippedCount}</p>
                    <p>{t('setup.backupRestore.errors') + data.errorFileNames.length}</p>
                    {errorList}
                </div>
                data.hasErrors ? Modal.error({ title: t('setup.backupRestore.statusError'), content: responseMessage }) : Modal.success({ title: t('setup.backupRestore.statusSuccessful'), content: responseMessage })
            })
            .catch(error => {
                this.setState({ loading: false });
                notificationError(t('setup.backupRestore.statusError'));
            });
    };
    changeOverwriteExisting = (value) => {
        this.setState({ overwriteExisting: value });
    }
    onFolderSearch = (value) => {
        this.setState({ fetching: true });
        fetchEntries(value, "entryManipulationDialog", data => this.onFolderFetched(data), null, ["folder"]);
    }
    onFolderFetched = (folderData) => {
        let filteredFolders = folderData.filter(f => f.type !== "home" && isUndefined(f.deleted));
        console.log(filteredFolders);
        this.setState({ fetchedFolders: filteredFolders, fetching: false });
    }
    onFolderSelected = (selectedFolderId) => {
        let selectedFolder = this.state.fetchedFolders.find((el) => el.id === selectedFolderId);
        this.setState({ folderId: selectedFolderId, selectedFolder: selectedFolder });
    };
    backupFileFromFrolder = () => {
        this.setState({ loading: true });
        const siteName = window.location.hostname;

        const now = new Date();
        const dateString = now.toLocaleDateString('en-GB', {
            day: '2-digit', month: '2-digit', year: 'numeric'
        }).replace(/\//g, '.');

        const hours = now.getHours() < 10 ? `0${now.getHours()}` : now.getHours();
        const minutes = now.getMinutes() < 10 ? `0${now.getMinutes()}` : now.getMinutes();


        const fileName = `${siteName}-backup-${this.state.selectedFolder.name}-${dateString}-${hours}h${minutes}m.zip`;

        downloadFile(`${config.url.API_URL}/backup/files/${this.state.folderId}`, fileName, (loadState) => {
            if (loadState) {
                this.setState({ loading: false });
            } else {
                notificationError("Error");
            }
        });
    }
    deselect = () => {
        this.setState({ folderId: null, selectedFolder: null });
    }
    render() {
        const { t } = this.props;
        const { loading, folderId, fetching, selectedFolder } = this.state;
        console.log(selectedFolder);
        let info = () => {
            Modal.info({
                title: t('setup.backupRestore.infoTitle'),
                content: (
                    <div>
                        {t('setup.backupRestore.infoText')}
                    </div>
                ),
                onOk() {
                },
            });
        };
        const draggerConfig = {
            name: 'file',
            multiple: false,
            action: `${config.url.API_URL}/restore/files`,
            showUploadList: false,
            customRequest: this.handleFileUpload,
            onChange(info) {
                const { status } = info.file;
                if (status === 'done') {
                    message.success(`${info.file.name} file uploaded successfully.`);
                } else if (status === 'error') {
                    message.error(`${info.file.name} file upload failed.`);
                }
            },
            onDrop(e) {
                console.log('Dropped files', e.dataTransfer.files);
            },
        };
        let folderOptions = [];

        if (!isUndefined(this.state.fetchedFolders)) {
            folderOptions = this.state.fetchedFolders.map(f => <Option value={f.id} key={f.id} disabled={f.isDisabled}>{f.name}</Option>)
        }
        let folder = selectedFolder ? <div style={{ fontSize: '16px' }}>
            <h4>{t('setup.backupRestore.textSelectedFolder')}</h4>
            <FolderOpenOutlined style={{ fontSize: '16px', marginRight: '5px' }} />{selectedFolder.name}<Button type="link" onClick={this.deselect}><CloseOutlined /></Button>
            <div style={{ marginTop: '10px' }}><Button type="primary" onClick={this.backupFileFromFrolder}>{t('setup.backupRestore.btnBackupFromFolder')}</Button></div>
        </div> : null

        return (
            <div>
                <PageHeader
                    title={t('setup.backupRestore.listTitle')}
                    subTitle={t('setup.backupRestore.listTitleHint')}
                    extra={[
                        <Button key="info-circle-button-key" icon={<InfoCircleOutlined />} /* type="primary" */ onClick={info}>
                        </Button>,
                    ]}
                >
                </PageHeader>

                <div className="steps-content">
                    <Spin spinning={loading} size="large">
                        <Tabs defaultActiveKey="1" tabPosition={isTablet() || isMobile() ? 'top' : 'left'}>
                            <Tabs.TabPane tab={t('setup.backupRestore.tabBackupFromFolder')} key="1">
                                <h2>{t('setup.backupRestore.titleBackupFromFolder')}</h2>
                                <Alert type="info" showIcon message={t('setup.backupRestore.alertBackupFromFolder')} />
                                <Select
                                    placeholder={t('setup.backupRestore.selectPlaceholder')}
                                    style={{ width: '70%', marginBottom: '20px', marginTop: '20px' }}
                                    onSelect={this.onFolderSelected}
                                    value={folderId}
                                    onSearch={this.onFolderSearch}
                                    filterOption={false}
                                    optionFilterProp="children"
                                    showSearch
                                    notFoundContent={fetching ? <Spin size="small" /> : <Empty></Empty>}
                                >
                                    {folderOptions}
                                </Select>
                                {folder}
                            </Tabs.TabPane>
                            <Tabs.TabPane tab={t('setup.backupRestore.tabGlobalBackup')} key="2">
                                <div>
                                    <h2>{t('setup.backupRestore.titleBackup')}</h2>
                                    <div className="backup-content" onClick={this.downloadBackup}>
                                        <p className="ant-upload-drag-icon">
                                            <CloudDownloadOutlined style={{ fontSize: '46px', color: '#40a9ff' }} />
                                        </p>
                                        <p className="ant-upload-text" style={{ fontSize: '16px' }}>{t('setup.backupRestore.btnBackup')} </p>

                                    </div>
                                    <br />
                                    <h2>{t('setup.backupRestore.titleRestore')}</h2>
                                    <span style={{ fontSize: '15px' }}><HighlightOutlined /> {t('setup.backupRestore.labelSwitch')}<Switch onChange={this.changeOverwriteExisting} checked={this.state.overwriteExisting} /></span>
                                    <Dragger {...draggerConfig} style={{ marginTop: '30px' }} >
                                        <p className="ant-upload-drag-icon">
                                            <InboxOutlined />
                                        </p>
                                        <p className="ant-upload-text">{t('setup.backupRestore.btnRestore')}</p>

                                    </Dragger>
                                </div>
                            </Tabs.TabPane>
                        </Tabs>
                    </Spin>

                </div>

            </div>
        );
    }
}

export default withTranslation()(BackupRestore);
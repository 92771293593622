import React from "react";
import { Button, PageHeader, Modal, Spin, Form, Tabs, Row, Col, InputNumber, Card, Tooltip, Switch, Checkbox, Radio, Input } from "antd";
import { withTranslation } from "react-i18next";
import { FolderOutlined, ForkOutlined, InfoCircleOutlined, SaveOutlined, TableOutlined, SettingOutlined,FormatPainterOutlined } from "@ant-design/icons";
import { isUndefined, mergeDeep } from "../../utils/JsObjectHelper";
import GeneralSetupMobile from "../adaptive/GeneralSetupMobile";
import { isMobile, isTablet } from "../../utils/AdaptiveLayout"
import Branding from "../../containers/branding/Branding";


const { TabPane } = Tabs;

class General extends React.Component {

    componentDidMount() {
        this.props.onMount();
    };

    onFinish = (values) => {
        //settings needs to be merged. FormItems in unopened tabs would otherwise be missed
        let mergedSettings = mergeDeep(this.props.generalSettingsObject, values);
        this.props.onSaveSettings({
            id: "general",
            name: "general",
            value: mergedSettings
        });
    };

    render() {
        const { t } = this.props;
        let info = () => {
            Modal.info({
                title: t('setup.general.infoTitle'),
                content: (
                    <div>
                        {t('setup.general.infoText')}
                    </div>
                ),
                onOk() {
                },
            });
        };

        const entryTechAttoptions = [
            { label: t('app.entry.tools.technical.tblCreated'), value: 'created' },
            { label: t('app.entry.tools.technical.tblCreatedBy'), value: 'createdByUserName' },
            { label: t('app.entry.tools.technical.tblLastUpdated'), value: 'updated' },
            { label: t('app.entry.tools.technical.tblLastUpdatedBy'), value: 'modifiedByUserName' },
            { label: t('app.entry.edit.lblFillQuality'), value: 'fillQuality' },
        ];

        let contentForm = <div className="steps-content"><Spin></Spin></div>;

        if (!isUndefined(this.props.generalSettingsObject)) {
            contentForm = <Form
                // layout={isTablet()?'vertical':'horizontal'}
                name="generalForm"
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                initialValues={this.props.generalSettingsObject}
                onFinish={this.onFinish}
                autoComplete="off"
            >
                <div className="steps-content">

                    <Tabs tabPosition={isTablet() ? 'top' : 'left'}>
                        <TabPane tab={t('setup.general.tabFolderDefaults')} key="fldr">
                            <Card>
                                <Form.Item label={t('app.entry.edit.lblSubfolderDisplayType')} key="subFolderDisplayType"
                                    name={["folderDefaults", "subFolderDisplayType"]} >
                                    <Radio.Group className="generalFormRadioBtn">
                                        <Radio.Button value="icons">
                                            <FolderOutlined />&nbsp;{t('app.entry.edit.lblSubfolderDisplayTypeIcons')}
                                        </Radio.Button>
                                        <Radio.Button value="table">
                                            <TableOutlined />&nbsp;{t('app.entry.edit.lblSubfolderDisplayTypeTable')}
                                        </Radio.Button>
                                    </Radio.Group>
                                </Form.Item>
                                <Form.Item label={t('app.entry.edit.lblChildrensDisplayType')} key="childrenDisplayType"
                                    name={["folderDefaults", "childrenDisplayType"]}
                                >
                                    <Radio.Group className="generalFormRadioBtn">
                                        <Radio.Button value="table">
                                            <TableOutlined />&nbsp;{t('app.entry.edit.lblChildrensDisplayTypeTable')}
                                        </Radio.Button>
                                        <Radio.Button value="hierarchy">
                                            <ForkOutlined rotate={180} />&nbsp;{t('app.entry.edit.lblChildrensDisplayTypeHierarchy')}
                                        </Radio.Button>
                                    </Radio.Group>
                                </Form.Item>
                                <Form.Item label={t('app.entry.edit.lblDisplayFirstAttributes')} key="displayTechAttributesFirst"
                                    name={["folderDefaults", "displayTechAttributesFirst"]}
                                    valuePropName="checked"
                                >
                                    <Switch checkedChildren={t('app.entry.edit.lblDisplayFirstTechAttributes')} unCheckedChildren={t('app.entry.edit.lblDisplayFirstUserAttributes')} />
                                </Form.Item>
                                <Form.Item label={t('app.entry.edit.lblChildrensTechAttributes')} key="childrenDisplayTechAttr"
                                    name={["folderDefaults", "childrenDisplayTechAttr"]}
                                >
                                    <Checkbox.Group options={entryTechAttoptions} className="generalFormCheckBox" style={{ display: 'grid' }} />
                                </Form.Item>
                            </Card>
                        </TabPane>
                        <TabPane tab={t('setup.general.tabContextEngine')} key="ce">
                            <Card>
                                <Form.Item
                                    label={
                                        <Tooltip title={t('setup.general.contextEngineDepthHint')}>
                                            {t('setup.general.contextEngineDepth')}
                                        </Tooltip>
                                    }
                                    name={["contextEngine", "depth"]}
                                >
                                    <InputNumber min={1} max={10} />
                                </Form.Item>
                            </Card>
                        </TabPane>
                        <TabPane tab={t('setup.general.tabLimits')} key="lim">
                            <Card>
                                <Form.Item
                                    label={
                                        <Tooltip title={t('setup.general.loadRelationAttDetailLimitHint')}>
                                            {t('setup.general.loadRelationAttDetailLimit')}
                                        </Tooltip>
                                    }
                                    name={["limits", "loadRelationAttDetailLimit"]}
                                >
                                    <InputNumber min={1} max={1000} />
                                </Form.Item>
                                <Form.Item
                                    label={
                                        <Tooltip title={t('setup.general.displayRelationTagLimitHint')}>
                                            {t('setup.general.displayRelationTagLimit')}
                                        </Tooltip>
                                    }
                                    name={["limits", "displayRelationTagLimit"]}
                                >
                                    <InputNumber min={1} max={1000} />
                                </Form.Item>
                                <Form.Item
                                    label={
                                        <Tooltip title={t('setup.general.hierarchySubTreeLimitHint')}>
                                            {t('setup.general.hierarchySubTreeLimit')}
                                        </Tooltip>
                                    }
                                    name={["limits", "hierarchySubTreeLimit"]}
                                >
                                    <InputNumber min={1} max={1000} />
                                </Form.Item>
                            </Card>
                        </TabPane>
                        <TabPane tab={t('setup.general.tabAutoTranslate')} key="at">
                            <Card>
                                <Form.Item
                                    label={
                                        <Tooltip title={t('setup.general.autoTranslateApiUrlHint')}>
                                            {t('setup.general.autoTranslateApiUrl')}
                                        </Tooltip>
                                    }
                                    name={["autoTranslate", "apiUrl"]}
                                >
                                    <Input  />
                                </Form.Item>
                                <Form.Item
                                    label={
                                        <Tooltip title={t('setup.general.autoTranslateApiKeyHint')}>
                                            {t('setup.general.autoTranslateApiKey')}
                                        </Tooltip>
                                    }
                                    name={["autoTranslate", "apiKey"]}
                                >
                                    <Input  />
                                </Form.Item>
                                <Form.Item
                                    label={
                                        <Tooltip title={t('setup.general.autoTranslateActiveHint')}>
                                            {t('setup.general.autoTranslateActive')}
                                        </Tooltip>
                                    }
                                    name={["autoTranslate", "active"]}
                                    valuePropName="checked"
                                >
                                    <Checkbox  />
                                </Form.Item>
                            </Card>
                        </TabPane>
                        <TabPane tab={t('setup.general.tabOthers')} key="other">
                            <Card>
                                There will be other settings ...
                            </Card>
                        </TabPane>
                    </Tabs>

                </div>
                <Row className="steps-action" justify="end" type="flex">
                    <Col>
                        <Form.Item>
                            <Button icon={<SaveOutlined />} type="primary" htmlType="submit">
                                {t('setup.general.btnSave')}
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>;
        }
        if (isMobile()) {
            contentForm = <GeneralSetupMobile generalSettingsObject={this.props.generalSettingsObject} onSaveSettings={this.props.onSaveSettings} />
        }
        let mainTabs = <Tabs tabPosition='top'  >
            <TabPane  tab={<span><SettingOutlined />{t('setup.menu.generalSetup')}</span>} type="card" key="general">
                {contentForm}
            </TabPane>
            <TabPane tab={<span><FormatPainterOutlined />{t('setup.branding.tabBranding')}</span>}  key="branding">
                <div className="steps-content">
                    <Branding />
                </div>
            </TabPane>
        </Tabs>

        return (
            <div>
                <PageHeader
                    title={t('setup.general.generalTitle')}
                    subTitle={t('setup.general.generalTitleHint')}
                    extra={[
                        <Button key="info-circle-button-key" icon={<InfoCircleOutlined />} /* type="primary" */ onClick={info}>
                        </Button>,
                    ]}
                >
                </PageHeader>
                {mainTabs}
            </div>
        );
    }

}

export default withTranslation()(General);
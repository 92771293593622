import request from "superagent";
import ResponseStatusException from "../exceptions/ResponseStatusException";
import {showError} from "./NotificationsHelper";
import {isArray, isEmptyObject, isEmptyValue, isObject} from "./JsObjectHelper";
import {allResponsesRemovedFromCache, requestIgnored, responseAddedToCache, responseRemovedFromCache} from "../actions/request";
import { config } from "../config/Config";

export const STATUS_DONE = 'done';
export const STATUS_RUNNING = 'running';

export const METHOD_POST = 'POST';
export const METHOD_GET = 'GET';
export const METHOD_PUT = 'PUT';
export const METHOD_HEAD = 'HEAD';
export const METHOD_DELETE = 'DELETE';
export const METHOD_PATCH = 'PATCH';

export const RESPONSE_TYPE_BLOB = 'blob';
export const RESPONSE_TYPE_ARRAY_BUFFER = 'arraybuffer';


/**
 * {
 *   entryTypes:
 *      {
 *          response: response, // Response object
 *          validUntil: 1508328315544
 *      },
 *   entryRelationTypes:
 *      {
 *          response: response, // Response object
 *          validUntil: 1508328315595
 *      },
 * }
 *
 *
 * @type {Object} json object
 */
let cachedResponses = {};

/**
 * Example of
 * {
 * /select/product/1:
 *      {
 *          request: object
 *          status: 'done'
 *      },
 *  /select/product/2:
 *      {
 *          request: object
 *          status: 'running'
 *      }
 * }
 *
 * @type {Object} json object
 */
let runningIgnoringRequests = {};

const clearExpiredCachedResponse = (url, key, dispatch) => {
    if(cachedResponses && cachedResponses[key] && cachedResponses[key].validUntil <= Date.now()) {
        delete  cachedResponses[key];
        if(dispatch)
            dispatch(responseRemovedFromCache(url, key));
    }
};

export const clearCachedResponses = (dispatch) => {
    cachedResponses = {};
    if(dispatch)
        dispatch(allResponsesRemovedFromCache());
};




/**
 * Create base options for fetch.
 *
 * @param {Number} page number of page for paging
 * @param {Number} size page size for paging
 * @param {String} sort sorting of results
 * @returns {RequestOptions} options with default values
 */
export const createBaseOptions = (page = 0, size = 0, sort = null) => {
    /** @type RequestOptions options **/
    let options = {
        headers: {
            Accept: 'application/json, application/hal+json, application/vnd.error+json'
        },
        query: {},
        method: METHOD_GET,
        timeout: {}
    };
    addPageableQueryParams(options.query, page, size, sort);
    return options;
};

/**
 * Create base options for oauth token.
 *
 * @param {String} clientId client id
 * @param {String} clientSecret client secret
 * @returns {RequestOptions} options with default values
 */
const createTokenRequestOptions = (clientId, clientSecret) => {
    /** @type RequestOptions options **/
    let options = {
        headers: {
            "X-SPACE-AUTH-KEY": "Basic " + Buffer.from(clientId + ":" + clientSecret).toString('base64'),
            Accept: 'application/json, application/hal+json, application/vnd.error+json'
        },
        query: {},
        method: METHOD_POST,
        timeout: {}
    };
    return options;
};


/**
 * Return base request object with authorization header
 *
 * @param {String} url url to call
 * @param {RequestOptions} options options with method, headers, body, timeout, query definition
 * @returns {Request} request object with authorization header
 */
const createBaseRequest = (url, options = {}) => {
    // set defaults such as Accept header
    let req = null;
    if(!isEmptyObject(options)) {
        const isMultipart = !isEmptyObject(options.files);
        if(options.method) {
            req = request(options.method, url);
        } else {
            req = request.get(url);
        }
        if(!isMultipart && options.body) {
            req.send(options.body);
        }
        if(options.headers && !isEmptyObject(options.headers)) {
            req.set(options.headers);
        }
        if(options.timeout && !isEmptyObject(options.timeout)) {
            req.timeout(options.timeout);
        }
        if(options.query && !isEmptyObject(options.query)) {
            if(isMultipart) {
                req.field(options.query);
            } else {
                req.query(options.query);
            }
        }
        if(isMultipart) {
            for (const file of options.files) {
                req.attach(file.name, file.file, file.options);
            }
        }
        if(options.responseType) {
            req.responseType(options.responseType);
        }
    } else {
        req = request.get(url);
    }
    let token = sessionStorage.getItem(`kcToken-${config.keycloak.url.replace(/(^\w+:|^)\/\//, '')}`);
    if(token) {
        req.set('Authorization', 'Bearer ' + token);
    }
    return req;
};

/**
 * Check status of request, if ok then return true.
 *
 * @param {Response} response
 * @param {Function} dispatch
 * @returns {Boolean} return true if response is ok
 */
const okStatus = (response, dispatch) => {
    return ((response.status >= 200 && response.status < 300));
};

/**
 * Check status of request, if ok then return response, else throw exception.
 *
 * @param {Response} response
 * @param {Function} dispatch
 * @returns {Response} return response or throw exception
 * @throws {Error} exception with returned response
 */
export const checkStatus = (response, dispatch) => {
    if (okStatus(response, dispatch)) {
        if(response instanceof Error && response.hasOwnProperty("response")) {
            return response.response;
        } else return response;
    } else {
        if(response instanceof Error && response.hasOwnProperty("response") && isObject(response.response)) {
            throw new ResponseStatusException(response.response);
        } else throw new ResponseStatusException(response);
    }
};

/**
 * Fetch request and then return response or error object.
 *
 * @param {String} url url to call
 * @param {RequestOptions} options options with method, headers, body, timeout definition
 * @param {Function} dispatch dispatch for actions
 * @param {Boolean} showErrorNotifications if dispatch specified, then send automatically notifications if error occurs
 * @returns {Promise<Response>|Error} response or error object
 */
export const fetch = (url, options = {}, dispatch = null, showErrorNotifications = null) => {
    if (options && options.cache && options.cache.key && options.cache.maxLifeTime) {
        // try to find response in cache
        if(cachedResponses && cachedResponses[options.cache.key] && cachedResponses[options.cache.key].response) {
            if(cachedResponses[options.cache.key].validUntil > Date.now() &&  !options.cache.forceRefresh) {
                return Promise.resolve(cachedResponses[options.cache.key].response);
            } else {
                delete cachedResponses[options.cache.key];
            }
        }
    }

    let req = createBaseRequest(url, options);

    if(options && options.ignore && options.ignore.key) {
        if(runningIgnoringRequests[options.ignore.key] && runningIgnoringRequests[options.ignore.key].request
          && runningIgnoringRequests[options.ignore.key].status === STATUS_RUNNING && runningIgnoringRequests[options.ignore.key].validUntil > Date.now()) {
            if(dispatch) {
                dispatch(requestIgnored(url, options.ignore.key));
            }
            return Promise.reject("Ignoring request");
        } else {
            let validUntil = Date.now() + options.ignore.maxLifeTime;
            runningIgnoringRequests[options.ignore.key] = {request: req, status: STATUS_RUNNING, validUntil: validUntil};
        }
    }

    return req.then(
        response => {
            if(options && options.ignore && options.ignore.key) {
                if(runningIgnoringRequests[options.ignore.key] && runningIgnoringRequests[options.ignore.key].request && runningIgnoringRequests[options.ignore.key].request === req) {
                    runningIgnoringRequests[options.ignore.key].status = STATUS_DONE;
                }
            }

            // TODO how check if the response is blob?
            if(!isEmptyObject(options) && options.responseType && (options.responseType === RESPONSE_TYPE_BLOB || options.responseType === RESPONSE_TYPE_ARRAY_BUFFER)) {
                response.blob = () => response.body;
            } else {
                response.json = () => response.body;
            }

            if (!response.ok) {
                // TODO redirect to login ???
                // if(response.status === 401) {
                //     dispatch(goToLoginPage());
                //     throw new IgnoredException("Redirect to login page");
                // }
                if (showErrorNotifications && dispatch) {
                    showError(dispatch, 'Error ocurred!', "There is a problem with a request!<br /><br /><strong>Url:</strong> " + response.url + "<br /><strong>Status:</strong> " + response.status + "<br /><strong>Status text:</strong> " + response.statusText, 0);
                }
            } else {
                if (options && options.cache && options.cache.key && options.cache.maxLifeTime) {
                    let shouldBeCached = true;
                    if(!!options.cache.shouldBeDataCached) {
                        shouldBeCached = options.cache.shouldBeDataCached(response);
                    }
                    if(shouldBeCached) {
                        let validUntil = Date.now() + options.cache.maxLifeTime;
                        // save response
                        cachedResponses[options.cache.key] = {
                            response: response,
                            validUntil: validUntil
                        };

                        if(options.cache.dynamicKeyCallback) {
                            let dynamicKey = options.cache.dynamicKeyCallback(response);
                            if(!!dynamicKey && dynamicKey !== options.cache.key) {
                                cachedResponses[dynamicKey] = {
                                    response: response,
                                    validUntil: validUntil
                                };
                                if (dispatch) {
                                    dispatch(responseAddedToCache(url, dynamicKey, validUntil));
                                }
                            }
                        }

                        if (dispatch) {
                            dispatch(responseAddedToCache(url, options.cache.key, validUntil));
                        }
                        setTimeout(clearExpiredCachedResponse, options.cache.maxLifeTime, url, options.cache.key, dispatch);
                    }
                }
            }
            return response;
        },
        error => {
            if(options && options.ignore && options.ignore.key) {
                if(runningIgnoringRequests[options.ignore.key] && runningIgnoringRequests[options.ignore.key].request && runningIgnoringRequests[options.ignore.key].request === req) {
                    runningIgnoringRequests[options.ignore.key].status = STATUS_DONE;
                }
            }

            if(!!error.response) {
                if (!error.response.ok) {
                    // TODO redirect to login ???
                    // if (error.response.status === 401) {
                    //     dispatch(goToLoginPage());
                    //     throw new IgnoredException("Redirect to login page");
                    // }
                    if(error.message.includes("ERROR: duplicate key value violates unique constraint")){
                        showError(dispatch, 'Error ocurred!', 'An object with the same name already exists' );
                    }
                    else{
                        showError(dispatch, 'Error ocurred!', 'There is a problem with a request! ' + error.message, 0);
                    }
                   
                }
            }
            return error;
        })
        .then(response => checkStatus(response, dispatch))
        .catch(error => {
            console.warn("REQUEST FAILED", error);
            throw error;
        });
};

/**
 * Add pageable query params to request.
 *
 * @param {Object} queryOptions json object with query params
 * @param {Number} page number of page for paging
 * @param {Number} size page size for paging
 * @param {String} sort sorting of results
 */
export const addPageableQueryParams = (queryOptions, page, size, sort) => {
    if(!isEmptyValue(page) && page > 0) {
        queryOptions.page = page;
    }
    if(!isEmptyValue(size) && size > 0) {
        queryOptions.size = size;
    }
    if(!isEmptyValue(sort)) {
        queryOptions.sort = sort;
    }
};

/**
 * Return binary data from response.
 *
 * @param {Response} response
 * @returns {ArrayBuffer} binary data
 */
const extractArrayBuffer = (response) => {
    return response.body;
};

/**
 * Return json object from response.
 *
 * @param {Response} response
 * @returns {Object} json object
 */
const extractJSON = (response) => {
    return response.body;
};

/**
 * Fetch request and then return promise of json object.
 *
 * @param {String} url url to call
 * @param {RequestOptions|null} options options with method, headers, body, timeout definition
 * @param {Function} dispatch dispatch for actions
 * @param {Boolean} showErrorNotifications if dispatch specified, then send automatically notifications if error occurs
 * @returns {Promise<Object>} promise of json object
 */
export const fetchJson = (url, options = null, dispatch = null, showErrorNotifications = null) => {
    return fetch(url, options, dispatch, showErrorNotifications)
        .then(extractJSON);
};

/**
 * Fetch request and then return promise of json object.
 *
 * @param {String} url url to call
 * @param {Object} baseOptions baseOptions with method, headers, body, timeout definition
 * @param {Object} options options with method, headers, body, timeout definition
 * @param {Array} data array of previous results
 * @returns {Promise<Object>} promise of json object
 */
const fetchJsonPageableViaHeaders = (url, baseOptions = null, options = null, data = []) => {
    return fetch(url, options)
        .then(response => {
            let jsonRes = extractJSON(response);
            if(!isArray(jsonRes)) return jsonRes;
            let mergedData = (isArray(data)) ? data.concat(jsonRes) : jsonRes;
            if(!isEmptyValue(response.header) && response.header.hasOwnProperty("link") && response.header.link.startsWith("<")) {
                // has next page
                let link =  response.header.link.substr(1);
                const index = link.indexOf(">");
                if(index > -1) {
                    link = link.substr(0, index);
                }
                return fetchJsonPageableViaHeaders(link, baseOptions, baseOptions, mergedData);
            }
            return mergedData;
        });
};

/**
 * Fetch request and then return promise of binary data
 *
 * @param {String} url url to call
 * @param {Object} options options with method, headers, body, timeout definition
 * @param {Function} dispatch dispatch for actions
 * @param {Boolean} showErrorNotifications if dispatch specified, then send automatically notifications if error occurs
 * @returns {Promise<ArrayBuffer>} promise of binary data
 */
export const fetchArrayBuffer = (url, options = null, dispatch = null, showErrorNotifications = null) => {
    if(options == null) {
        options = {};
    }
    options.responseType = RESPONSE_TYPE_ARRAY_BUFFER;
    return fetch(url, options, dispatch, showErrorNotifications)
        .then(extractArrayBuffer);
};
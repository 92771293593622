const docker = {
   url: {
        WS_URL: window._env_.APHINITFE_WS_URL,
        API_URL: window._env_.APHINITFE_API_URL,
        FILES_URL: window._env_.APHINITFE_FILES_URL,
        DRAWIO_URL: window._env_.APHINITFE_DRAWIO_URL,
        BPMN_URL: window._env_.APHINITFE_BPMN_URL
    }, 
    keycloak: {
        realm: window._env_.APHINITFE_KEYCLOAK_REALM,
        url: window._env_.APHINITFE_KEYCLOAK_URL,
        clientId: window._env_.APHINITFE_KEYCLOAK_CLIENTID
    },
    version: {
        number: window._env_.APHINIT_VERSION_AND_DATE.split('@')[0],
        date: new Date(window._env_.APHINIT_VERSION_AND_DATE.split('@')[1])
    }
};
/*const local = {
    url: {
        WS_URL: "ws://localhost:8484/app-websocket",
        API_URL: "http://localhost:8484/api",
        FILES_URL: "http://localhost:8484/files",
        DRAWIO_URL: "https://draw.aphinit.com",
        BPMN_URL: "https://dev-bpmn.aphinit.com"
    },
    keycloak: {
        realm: "dev",
        url: "https://keycloak.aphinit.com/auth",
        clientId: "dev-web-aphinit"
    }
};
const prod = {
    url: {
        WS_URL: "wss://app-be.aphinit.com/app-websocket",
        API_URL: "https://app-be.aphinit.com/api",
        FILES_URL: "https://app-be.aphinit.com/files",
        DRAWIO_URL: "https://draw.aphinit.com",
        BPMN_URL: "https://app-bpmn.aphinit.com"
    },
    keycloak: {
        realm: "app",
        url: "https://keycloak.aphinit.com/auth",
        clientId: "aphinit-web"
    }
};
const dev = {
    url: {
        WS_URL: "wss://dev-be.aphinit.com/app-websocket",
        API_URL: "https://dev-be.aphinit.com/api",
        FILES_URL: "https://dev-be.aphinit.com/files",
        DRAWIO_URL: "https://dev-draw.aphinit.com",
        BPMN_URL: "https://dev-bpmn.aphinit.com",
        //LIBRETRANSLATE_BASE_URL:"https://dev-translate.aphinit.com",
    },
    //libreTranslate: {
    //    LIBRETRANSLATE_API_KEY:"14c9939b-c93d-4667-885b-4a6869afbaa4"
    //},
    keycloak: {
        realm: "dev",
        url: "https://dev-keycloak.aphinit.com",
        clientId: "dev-web-aphinit"
    },
    version: {
        number: "v0.9.7",
        date: new Date("12-05-2022")
    }
};*/

//Jakub test
export const config = docker; //process.env.NODE_ENV === "development" ? dev : prod;
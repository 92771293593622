import {fetchJson} from "../utils/SuperAgentFetch";
import {config} from "../config/Config";


/**
 * 
 * @param {String} lineageId 
 * @param {Function<Array[Object]>} callback 
 */
export const fetchLineageUsedInFolders = (lineageId, callback) => {
    let r = fetchJson(`${config.url.API_URL}/settings/custom/item/lineage/${lineageId}/usedInFolders`);
    r.then(json => {
        console.log("fetchLineageUsedInFolders", json);
        if(json._embedded && json._embedded.bmcEntries && json._embedded.bmcEntries.length > 0) {
            callback(json._embedded.bmcEntries);
        } else {
            callback([]);
        }
    });
};
